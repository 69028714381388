<template>
  <div class="px-10">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h4 primary--text">ข้อมูลรถ</div>
      <div style="width: 400px">
        <v-text-field
          class="ml-10"
          v-model="filter.start_with"
          prepend-inner-icon="mdi-magnify"
          label="ค้นหา เลขทะเบียน ชื่อลูกค้า เบอร์โทร"
          outlined
          rounded
          dense
          single-line
          hide-details
          @keyup="searchData"
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined small class="ml-5" @click="add()">
        <v-icon left small>mdi-plus</v-icon> เพิ่มข้อมูลรถ
      </v-btn>
    </v-toolbar>
    <v-card outlined class="mb-5">
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              label="ประเภทรถ"
              v-model="filter.car_type"
              :items="car_type"
              item-value="value"
              item-text="name"
              outlined
              rounded
              dense
              hide-details
            >
            </v-select>
          </v-col>
          <v-col>
            <v-select
              label="กรองจากวันที่ขาด"
              v-model="filter.product_type"
              :items="product_type"
              item-value="value"
              item-text="name"
              outlined
              rounded
              dense
              hide-details
            >
            </v-select>
          </v-col>
          <v-col>
            <DatePicker
              label="วันที่เริ่ม"
              :value="filter.start_date"
              @onChange="(val) => (filter.start_date = val)"
              hideDetails
              outlined
              rounded
              dense
            />
          </v-col>
          <v-col>
            <DatePicker
              label="ถึงวันที่"
              :value="filter.end_date"
              @onChange="(val) => (filter.end_date = val)"
              hideDetails
              outlined
              rounded
              dense
            />
          </v-col>
          <v-spacer />
          <v-col>
            <v-btn
              color="primary"
              small
              class="float-right"
              @click="download_excel()"
            >
              ดาวน์โหลด <v-icon right>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="primary" small class="float-right" @click="getData()">
              แสดงรายงาน <v-icon right>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="calories"
      :loading-text="$table_loading_text"
      :page.sync="page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      item-key="_id"
      @click:row="edit"
    >
      <template v-slot:[`item._id`]="{ item }">
        {{
          list
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) +
          1 +
          filter.item_per_page * (filter.page - 1)
        }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <menu-ship small v-model="item.status" />
      </template>

      <template v-slot:[`item.plate`]="{ item }">
        {{ item.plate_character }} - {{ item.plate_number }}
      </template>
      <template v-slot:[`item.car_type`]="{ item }">
        {{
          car_type_dict.filter((el) => {
            return el.value == item.car_type;
          })[0].name
        }}
      </template>

      <template v-slot:[`item.taxout_date`]="{ item }">
        <span v-if="item.taxout_date != ''">
          {{ formatDate(item.taxout_date) }}
        </span>
      </template>

      <template v-slot:[`item.third_insurance_end_date`]="{ item }">
        <span v-if="item.third_insurance_end_date != ''">
          {{ formatDate(item.third_insurance_end_date) }}
        </span>
      </template>

      <template v-slot:[`item.insurance_end_date`]="{ item }">
        <span v-if="item.insurance_end_date != ''">
          {{ formatDate(item.insurance_end_date) }}
        </span>
      </template>

      <template v-slot:[`item.ownership.phone_number`]="{ item }">
        <span >
          {{ phoneNumberFormat(item.ownership.phone_number) }}
        </span>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import * as moment from 'moment';
import DatePicker from '@/components/DatePicker.vue';

export default {
  components: {
    DatePicker,
  },
  data: () => ({
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    showPhoneNumber: false,
    list: [],
    headers: [
      { text: '#', value: '_id', filterable: false, sortable: false, width: 0},
      { text: 'ทะเบียน', value: 'plate' },
      { text: 'จังหวัด', value: 'plate_province', filterable: false },
      { text: 'ลูกค้า', value: 'ownership.customer_name' },
      { text: 'เบอร์ติดต่อ', value: 'ownership.phone_number' },
      { text: 'ประเภทรถ', value: 'car_type', filterable: false },
      { text: 'วันที่ภาษีขาด', value: 'taxout_date' },
      { text: 'วันที่ พ.ร.บ ขาด', value: 'third_insurance_end_date' },
      { text: 'วันที่ประกันภัยขาด', value: 'insurance_end_date' },
      { text: '', value: 'actions', sortable: false, filterable: false },
    ],

    car_type: [
      { value: 0, name: 'ทั้งหมด' },
      { value: 1, name: 'รถยนต์เท่านั้น' },
      { value: 2, name: 'รย.1' },
      { value: 3, name: 'รย.2' },
      { value: 4, name: 'รย.3' },
      { value: 5, name: 'รย.12' },
      { value: 6, name: 'อื่นๆ' },
    ],
    product_type: [
      { value: 0, name: 'ทั้งหมด' },
      { value: 1, name: 'เฉพาะ ภาษี' },
      { value: 2, name: 'เฉพาะ พ.ร.บ.' },
      { value: 3, name: 'เฉพาะ ประกัน' },
      { value: 4, name: 'ภาษี + พ.ร.บ.' },
      { value: 5, name: 'พ.ร.บ. + ประกัน' },
      { value: 6, name: 'ภาษี + พ.ร.บ. + ประกัน' },
    ],
    filter: {
      branch_id: '',
      item_per_page: 20,
      page: 1,
      start_with: '',
      car_type: 0,
      product_type: 0,
      start_date: moment().add(-1, 'years').format('YYYY-MM-DD'),
      end_date: moment().add(1, 'years').format('YYYY-MM-DD'),
    },
    car_type_dict: [
      { value: 'CT1', name: '(รย.1)' },
      { value: 'CT2', name: '(รย.2)' },
      { value: 'CT3', name: '(รย.3)' },
      { value: 'CT4', name: '(รย.12)' },
      { value: 'CT5', name: '(รย.13)' },
      { value: 'CT14', name: '(รย.15)' },
      { value: 'CT13', name: '(รย.17)' },
      { value: 'CT6', name: '(ขส.10)' },
      { value: 'CT7', name: '(ขส.30)' },
      { value: 'CT8', name: '(ขส.40)' },
      { value: 'CT9', name: '(ขส.70)' },
      { value: 'CT10', name: '(ขส.80)' },
      { value: 'CT11', name: '(รย.1EV)' },
      { value: 'CT12', name: 'อื่นๆ' },
    ],
  }),

  async created() {
    this.filter.branch_id = await this.$store.state.selected_branch._id;
    await this.getData();
    await this.getShowPhoneNumber()
  },

  methods: {
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    add() {
      this.$router.push({ name: 'car-create' });
    },
    edit(item) {
      this.$router.push({ name: 'car-update', params: { id: item._id } });
    },
    remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign({ car_id: item._id }, this.$privateKey, {
                noTimestamp: true,
              }),
            };
            await this.$axios
              .post(`${this.$baseUrl}/car/delete_car`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch((err) => {
                this.$alertServerError({ title: err.error_message });
              });
            console.log('remove()');
          }
        }
      );
    },
    async getShowPhoneNumber() {
      try {
        if (Object.keys(this.$store.state.group_permissions).length === 0) {
            await this.$store.dispatch('getGroupPermissions');
        };

        this.show_phone_number = this.$store.state.group_permissions.show_phone_number;
        if (this.show_phone_number == 0) {
          this.showPhoneNumber = false;
        } else {
          this.showPhoneNumber = true;
        };
      } catch (error) {
        this.showPhoneNumber = true;
        console.error('Error in getShowPhoneNumber:', error);
      };
    }, 
    phoneNumberFormat(val) {
      if (val) {
        if (!this.showPhoneNumber) return 'XXX-XXXXXXX';
        return val.substring(0, 3) + '-' + val.substring(3, val.length);
      } else return val;
    },
    async searchData() {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/car/get_car`, body)
        .then((res) => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
      }, 1000);
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/car/get_car_from_product`, body)
        .then((res) => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    async download_excel() {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      var date1 = new Date(this.filter.start_date);
      var date2 = new Date(this.filter.end_date);
      var diffTime = date2.getTime() - date1.getTime();
      var diffDay = Math.floor(diffTime / _MS_PER_DAY);

      // Discard the time and time-zone information.
      console.log(diffDay);
      if (diffDay > 190) {
        this.$alertServerError({
          title:
            'ไม่สามารถดาวน์โหลด<br/> มากกว่า 6 เดือน/ครั้งได้ <br/> กรุณาเลือกวันให้น้อยกว่า 6 เดือน',
        });
      } else {
        this.loading = true;
        let body = {
          token: this.$jwt.sign(this.filter, this.$privateKey, {
            noTimestamp: true,
          }),
        };
        await this.$axios({
          method: 'post',
          url: `${this.$baseUrl}/car/download_car_from_product`,
          responseType: 'blob',
          data: body,
        })
          .then((response) => {
            if (
              moment(this.filter.start_date).format('DDMMYYYY') !=
              moment(this.filter.end_date).format('DDMMYYYY')
            ) {
              this.forceFileDownload(
                response,
                'ข้อมูลรถ' +
                  moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                    'DD-MM-YYYY'
                  ) +
                  '_' +
                  moment(this.filter.end_date, 'YYYY-MM-DDTHH:mm:ss').format(
                    'DD-MM-YYYY'
                  ) +
                  '.xlsx'
              );
            } else {
              this.forceFileDownload(
                response,
                'ข้อมูลรถ' +
                  moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                    'DD-MM-YYYY'
                  ) +
                  '.xlsx'
              );
            }
          })
          .catch((err) => {
            console.log('err', err);
            this.$alertServerError({ title: err.error_message });
          });
        this.loading = false;
      }
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      if (m_d === 'Invalid date') return '';
      return `${m_d}/${year + 543}`;
    },
  },
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
